
import Header from "../components/shared/Header.vue";
export default {
  props: {
    center: {
      default: false,
      type: Boolean,
    },
  },
  components: { Header },
};
