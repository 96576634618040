import Vue from 'vue';
import VueGtag from 'vue-gtag';

export default ({ app }) => {
  Vue.use(VueGtag, {
    config: { id: 'G-07VQVT4JXM', send_page_view: true },
    bootstrap: true,
    enabled: true,
    appName: 'Facta',
    pageTrackerScreenviewEnabled: true,
    pageTrackerEnabled: true,
    send_page_view: true,
    sendPageView: true,
    includes: [
      {
        id: 'AW-16616229009',
      }
    ],
  }, app.router);
}
